.elipses {
    position: relative;
    max-width: 100vw;
}
  
.elipse1 {
    position: absolute;
    width: 15%;
    height: 15%;
    left: 1500px;
    top: 40px;
    z-index: -1;
    background: radial-gradient(116.85% 116.85% at 3.7% -4.75%, #DA008E 13.34%, #250F7D 61.15%);
    filter: blur(100px);
}

.elipse2 {
    position: absolute;
    width: 15%;
    height: 15%;
    background: radial-gradient(116.85% 116.85% at 3.7% -4.75%, rgba(65, 196, 214, 0.8) 13.34%, rgba(111, 16, 156, 0.89) 61.15%);
    filter: blur(100px);
}