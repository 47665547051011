#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-container {
    background: var(--grad-3);
    margin-top: 60px;
    border-radius: 20px;
    width: 50%;
    min-width: 300px;
    opacity: 70%;
}

.contact-content-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.contact-input-control {
    margin: 1.5rem 0;
}


.name-email-con {
    display: flex;
}
.name-email-con :last-child {
    margin-left: 15px;
}

.contact-input-control input {
    border-radius: 20px;
    font-weight: 600;
    font-size: inherit;
    font-family: inherit;
    padding: 0.8rem 1.1rem;
    outline: none;
    border: none;
    background-color: var(--color-primary);
    width: 100%;
}

  
.submit-btn {
    border-radius: 30px;
    color: inherit;
    font-weight: 600;
    position: relative; /*Need this here or the grey overlay will fill the entire parent*/
    bottom: 10px;
    border: 1px solid var(--color-secondary);
    display: flex;
    align-items: center;
    overflow: hidden;
    text-decoration: none;
    width: 160px;
    margin:auto;
    cursor: pointer;
}

.submit-btn .btn-text {
    padding: 0 2rem;
}

.submit-btn .btn-icon {
    background: var(--grad-1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 1rem;
}

.submit-btn::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: all 0.4s ease-in-out;
    z-index: -1;
}

.submit-btn:hover::before {
    width: 100%;
    height: 100%;
    background: var(--grad-2);
    transform: translateX(0);
}