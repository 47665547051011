#home {
    min-height: 100vh;
    max-width: 100%;
    color: var(--color-white);
    overflow: hidden;
}

/*Header-content*/
.header-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    min-height: 100vh;
}

.header-content .left-header {
    display: flex;
    align-items: center;
    position: relative;
}

.header-content .left-header .h-shape {
    transition: all 0.4s ease-in-out;
    width: 65%;
    height: 100%;
    background: var(--grad-1);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 46% 0, 79% 100%, 0% 100%);
            clip-path: polygon(0 0, 46% 0, 79% 100%, 0% 100%);
}

.header-content .left-header .image {
    border-radius: var(--br-sm-2);
    height: 90%;
    width: 68%;
    margin-left: 4rem;
    background-color: var(--color-black);
    transition: all 0.4s ease-in-out;
}

.header-content .left-header .image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
        object-fit: cover;
    transition: all 0.4s ease-in-out;
    border-radius: var(--br-sm-2);
    filter: grayscale(100%);
}

.header-content .left-header .image img:hover {
    filter: grayscale(0);
}

.header-content .right-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 18rem;
}

.header-content .right-header .name {
    font-size: 3rem;
}

.header-content .right-header .name span {
    background: var(--grad-1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header-content .right-header p {
    margin: 1.5rem 0;
    line-height: 2rem;
}

/*Independent Components*/
.btn-con {
    display: flex;
    align-self: flex-start;
}
  
.main-btn {
    border-radius: 30px;
    color: inherit;
    font-weight: 600;
    position: relative;
    bottom: 10px;
    border: 1px solid var(--color-secondary);
    display: flex;
    align-self: flex-start;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-decoration: none;
}

.main-btn .btn-text {
    padding: 0 2rem;
}

.main-btn .btn-icon {
    background: var(--grad-1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 1rem;
}

.main-btn::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: all 0.4s ease-in-out;
    z-index: -1;
}

.main-btn:hover::before {
    width: 100%;
    height: 100%;
    background: var(--grad-3);
    transform: translateX(0);
}



.hide-section {
    display: none;
    transform: translateY(0) scale(1);
}

.active {
    display: block;
    -webkit-animation: scaleAnim 1s ease-in-out;
            animation: scaleAnim 1s ease-in-out;
}
@-webkit-keyframes scaleAnim {
    0% {
        transform: translateY(-100%) scaleY(0);
}
    100% {
        transform: translateY(0) scaleY(1);
}
}
@keyframes scaleAnim {
    0% {
        transform: translateY(-100%) scaleY(0);
    }
    100% {
        transform: translateY(0) scaleY(1);
    }
}