.skillContainer {
    width: 28%;
    min-width: 320px;
    margin: 50px;
    overflow: hidden;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    /* -webkit-backdrop-filter: blur(5px); */
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.column h3 {
    text-align: center;
    white-space: nowrap;
    padding-top: 10px;
    font-size: 1.6rem;
    color: #fff;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
}
@media screen and (max-width:1520px) {
    .column h3 {
    font-size: 1.4rem;
    }
}
.skillContentContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    grid-gap: 1em;
    padding: 20px 20px 10px 20px;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 800;
  background: var(--grad-3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 25%;
}

.list:hover {
    background: var(--grad-1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}