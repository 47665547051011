@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

:root {
  --color-primary: #0F0926;
  --color-secondary: #6F109C;
  --color-white: #FFFFFF;
  --color-black: #000;
  --color-grey0: #f8f8f8;
  --color-grey-1: #dbe1e8;
  --color-grey-2: #b2becd;
  --color-grey-3: #6c7983;
  --color-grey-4: #454e56;
  --color-grey-5: #2a2e35;
  --color-grey-6: #12181b;
  --br-sm-2: 14px;
  --box-shadow-1: 0 3px 15px rgba(0,0,0,.3);
  --grad-1: linear-gradient(90deg, rgb(153, 37, 207) 0%, rgb(57, 111, 249) 100%);
  --grad-2: linear-gradient(90deg, rgb(57, 111, 249) 0%, rgb(153, 37, 207) 100%);
  --grad-3: linear-gradient(90deg, rgb(234, 136, 81) 0%, rgb(237, 32, 255) 100%);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-primary);
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  color: var(--color-white);
  transition: all 0.4s ease-in-out;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-title {
  text-align: center;
}

.main-title h2 {
  position: relative;
  text-transform: uppercase;
  font-size: 7vh;
  font-weight: 700;
}

.main-title h2 span {
  background: var(--grad-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-title h2 .bg-text {
  position: absolute;
  white-space: nowrap;
  top: 50%;
  left: 50%;
  color: var(--color-grey-5);
  transition: all 0.4s ease-in-out;
  z-index: -1;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 8vh;
}
