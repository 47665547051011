.controls {
    position: fixed;
    z-index: 10;
    top: 50%;
    right: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
}

.controls .active-btn {
    background: linear-gradient(90deg, rgb(153, 37, 207) 0%, rgb(57, 111, 249) 100%) !important;
    transition: all 0.4s ease-in-out;
}

.controls .navbarReactLink {
    text-decoration: none;
}

.controls .control {
    padding: 1rem;
    cursor: pointer;
    background-color: var(--color-grey-3);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.7rem 0;
    box-shadow: var(--box-shadow-1);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.controls .control i {
    font-size: 1.2rem;
    color: var(--color-black);
    pointer-events: none;
}


@media only screen and (max-width: 500px) {
    .controls {
        position: fixed;
        z-index: 10;
        top: 20%;
        right: 3%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
    }
    
    .controls .active-btn {
        background: linear-gradient(90deg, rgb(153, 37, 207) 0%, rgb(57, 111, 249) 100%) !important;
        transition: all 0.4s ease-in-out;
    }
    
    .controls .navbarReactLink {
        text-decoration: none;
    }
    
    .controls .control {
        padding: 1rem;
        cursor: pointer;
        background-color: var(--color-grey-3);
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem 0;
        box-shadow: var(--box-shadow-1);
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
    
    .controls .control i {
        font-size: 1.2rem;
        color: var(--color-black);
        pointer-events: none;
    }
  }